@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ool1h8');
  src:  url('fonts/icomoon.eot?ool1h8#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?ool1h8') format('truetype'),
  url('fonts/icomoon.woff?ool1h8') format('woff'),
  url('fonts/icomoon.svg?ool1h8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-04:before {
  content: "\e900";
}
.icon-Numbers:before {
  content: "\e901";
  color: #667085;
}
.icon-plus:before {
  content: "\e902";
}
.icon-search-lg:before {
  content: "\e903";
}
.icon-settings-01:before {
  content: "\e904";
}
.icon-toggle-01-right:before {
  content: "\e905";
}
.icon-type-square:before {
  content: "\e906";
}
.icon-user-01:before {
  content: "\e907";
}
.icon-users-01:before {
  content: "\e908";
}
.icon-x-close:before {
  content: "\e909";
}
.icon-arrow-left:before {
  content: "\e90a";
}
.icon-arrow-right:before {
  content: "\e90b";
}
.icon-arrow-up:before {
  content: "\e90c";
}
.icon-Button-1:before {
  content: "\e90d";
}
.icon-Button:before {
  content: "\e90e";
}
.icon-calendar:before {
  content: "\e90f";
}
.icon-chevron-down:before {
  content: "\e910";
}
.icon-filter-lines:before {
  content: "\e911";
}
.icon-Icon:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e913";
}
