.custom-table {
  .ant-spin-blur .ant-table-sticky-holder {
    top: 0 !important;
  }
  margin-bottom: 0;
  .ant-table-expanded-row {
    & > .ant-table-cell {
      background-color: #efeffc;
    }
  }
  .ant-table-sticky-holder {
    z-index: 1000;
  }
  .ant-pagination-item,
  .ant-pagination-total-text {
    margin-right: 3px;
  }
  .ant-table.ant-table-middle {
    .ant-table-thead {
      background: #f5f5f5;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
      > tr > th {
        padding: 11px 12px;
        color: #222;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 12px 12px;
    }
  }
  &-pagination {
    color: #000;
    margin: 20px 0;
    .ant-row {
      align-items: center;
      display: flex;
    }
    .ant-select {
      color: #222;
      &:hover,
      &.ant-select-open {
        .ant-select-selector {
          background: #2a2a86 !important;
          color: #fff;
        }
        .ant-select-selection-item {
          color: inherit;
        }
        .ant-select-arrow {
          color: #fff;
        }
      }
      .ant-select-selector {
        background: $border-color;
        border: none;
        border-radius: 23.5px;
        padding: 0 20px;
        transition: 0.3s ease;
      }
      .ant-select-selection-item {
        font-weight: bold;
        transition: none;
      }
    }
    &__main {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .ant-pagination-item {
        background: #f5f5f5;
        border-radius: 2px;
        color: #222;
        height: 35px;
        line-height: 35px;
        min-width: 35px;
        &:not(.ant-pagination-disabled) {
          &:hover,
          &.ant-pagination-item-active {
            background: #2a2a86;
            color: #fff;
            a {
              color: #fff !important;
            }
          }
        }
        &.ant-pagination-disabled {
          background: #f5f5f5;
          color: #9e9e9e;
        }
      }
      .ant-pagination-jump-next,
      .ant-pagination-jump-prev,
      .ant-pagination-next,
      .ant-pagination-prev {
        height: 35px;
        line-height: 35px;
        min-width: 35px;
        .ant-pagination-item-link {
          border-radius: 2px;
        }
        &:not(.ant-pagination-disabled) {
          &:hover {
            .ant-pagination-item-link {
              background: #2a2a86;
              color: #fff;
            }
          }
        }
      }

      .ant-pagination-first,
      .ant-pagination-last {
        .ant-pagination-item-link {
          padding: 0 10px;
        }
        span[role="img"] {
          position: relative;
          top: 1px;
        }
      }
    }
    &__sizeChange {
      > label {
        color: inherit;
      }
    }
    &__showTotal {
      color: #000;
    }
    .ant-pagination-next,
    .ant-pagination-prev {
      display: none;
    }
    .ant-pagination-last {
      margin-left: 1px;
    }
  }

  &-setting-column {
    background-color: transparent;
    border-color: transparent;
  }
  &__has-border-bottom {
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }
  &__has-select-radio {
    input,
    label {
      cursor: pointer;
    }
  }
  .tr-hover {
    tr {
      cursor: pointer;
      &:hover {
        color: #2a2a86;
      }
    }
  }
  .data-hover {
    color: #5656a2;
    cursor: pointer;
    &:hover {
      color: #2a2a86;
    }
  }
}
