@import "variable";
@import "overwrite";
@import "page-header";
@import "table";
#root {
  width: 100%;
  height: 100%;
  font-family: $font-family;
}

* {
  font-family: $font-family;
}

.ant-layout-sider-collapsed {
  .logo {
    padding-left: 20px;
    margin-right: 24px;
  }
  .ant-layout-sider-user {
    padding-left: 0;
    justify-content: center;
    .sider-user-info,
    .sider-user-info-icon {
      display: none;
    }
  }
}

.ant-tag {
  font-size: 14px;
}

.logo {
  height: 44px;
  padding-left: 32px;
  overflow: hidden;
  margin: 20px 0;
  img {
    width: 90px;
  }
}

.btn {
  &-collapsed {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 20px;
    right: 1px;
    border: 0;
    padding: 0;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    box-shadow: 0 0 2px 1px #eee;
  }
  &-move-left,
  &-move-right {
    display: flex;
    width: 16px;
    height: 16px;
    border: 1px solid $red;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 11px;
      color: $red;
    }
  }
  &-move-right {
    border-color: #2d9cdb;
    span {
      color: #2d9cdb;
    }
  }
}

.loading {
  &-fullscreen {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(204, 204, 204, 0.3);
    justify-content: center;
    align-items: center;
  }
}

.container {
  margin-left: 300px;
  transition: all 0.1s;
  @media screen and (max-width: 1439px) {
    margin-left: 200px;
  }
  &.collapsed {
    margin-left: 52px;
  }
}

a {
  color: $primary-color;
}

label {
  color: $label-color;
}

.text {
  &-default {
    color: $text-body-color;
  }
  &-success {
    color: $green;
  }
  &-error {
    color: $red;
  }
  &-secondary {
    color: $secondary-color;
  }
  &-primary {
    color: $primary-color;
  }
  &-center {
    text-align: center;
  }
  &-muted {
    color: $text-muted;
  }
}

.notfound {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  h1 {
    font-size: 48px;
    margin: 0;
  }
}

.loading {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: #dddddd60;
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-top: 5px;
}
